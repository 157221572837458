<template>
    <v-navigation-drawer
        :value="true"
        :mini-variant="autohide"
        :expand-on-hover="autohide"
        :permanent="true"
        :floating="false"
        absolute
        light>
        <NavMenu v-if="panel === 'menu'" />
        <NavCountry v-if="panel === 'country'" />
    </v-navigation-drawer>
</template>

<script>
import NavMenu from "./NavMenu";
import NavCountry from './NavCountry'
import {mapFields} from "vuex-map-fields";

export default {
    name: 'NavMain',
    components: {NavMenu, NavCountry},
    computed: {
        ...mapFields('navigation', ['currentCountry','autohide']),
        panel() {
            if (this.currentCountry !== null)
                return 'country'

            return 'menu'
        }
    }
}
</script>

<style scoped>
.nav__toggle {
    padding: 0;
}
</style>
