import {find} from "lodash";
import decodePath from "../../helpers/decodepath";

export function routePolylines(state, getters, globalState, globalGetters) {
    const points = globalState.points.items;
    const routes = globalState.routes.items;

    const polylines = [];

    routes.map(route => {
        const finalWay = [];

        // Because we construct the polyline with pairs, the last stop will be included in the last loop (length - 1)
        for (let i = 0; i < route.stops.length - 1; i++) {
            const thisStop = route.stops[i].id;
            const nextStop = route.stops[i+1].id;

            // We try to find a Way between the two points
            const wayFragment = find(state.ways, way =>
                (way.points[0] === thisStop && way.points[1] === nextStop)
            );

            if (wayFragment) {
                const decodedPaths = decodePath(wayFragment.polyline);
                finalWay.push(decodedPaths);
            }

            // If we don't have a detailed path, we create a straight line
            else {
                //console.log(`${thisStop} -> ${nextStop} detailed path not found`);

                const currentLatLng = globalGetters['points/getById'](thisStop).latlng;
                const nextLatLng = globalGetters['points/getById'](nextStop).latlng;

                // Always add the first fragment if we don't have a detailed polyline
                if (i === 0) {
                    finalWay.push([currentLatLng]);
                }

                finalWay.push([
                    [currentLatLng[0], currentLatLng[1]],
                    [nextLatLng[0], nextLatLng[1]]
                ]);
            }
        }

        polylines.push({
            id: route.id,
            data: finalWay
        });
    });

    return polylines;
}

export function enabledPoints(state, getters, globalState) {
    const category = globalState.navigation.currentCategory;

    const points = globalState.points.items;
    let filteredPoints;

    if (category === '')
        filteredPoints = points;
    else
        filteredPoints = points.filter(point => point.category === category );

    return filteredPoints.map(_ => _.id)
}

export function enabledRoutes(state, getters, globalState) {
    const category = globalState.navigation.currentCategory;

    if (category !== '') return [];

    return globalState.routes.items.map(_ => _.id);
}

export function flyTo(state, getters, globalState, globalGetters) {
    const {currentPoint, currentRoute} = globalState.navigation;

    if (currentPoint !== null) {
        const pointData = globalGetters['points/getById'](currentPoint);
        return pointData.latlng;
    }

    if (currentRoute !== null) {
        const routeData = globalGetters['routes/getById'](currentRoute);
        const firstPointId = routeData.stops[0].id;

        const point = globalGetters['points/getById'](firstPointId);
        return point.latlng;
    }

    return null;
}
