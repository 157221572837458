import {CLEAR_NAVIGATION} from '../mutation-types';

export async function navigateToRoute(context, {routeId, resetNav = true}) {
    const updateField = (path, value) => context.commit('updateField', { path, value });

    if (resetNav) context.commit(CLEAR_NAVIGATION);

    await updateField('currentRoute', routeId);

    const countryId = context.rootGetters['routes/getById'](routeId).country_id;
    await updateField('currentCountry', countryId);
}

export async function navigateToRoutePoint(context, {routeId, pointId, resetNav = true}) {
    const updateField = (path, value) => context.commit('updateField', { path, value });

    if (resetNav) context.commit(CLEAR_NAVIGATION);

    await context.dispatch('navigation/navigateToRoute', {routeId, clearNav: false});
    await updateField('currentPoint', pointId);
}

export async function navigateToPoint(context, {routeId, pointId, resetNav = true}) {
    const updateField = (path, value) => context.commit('updateField', { path, value });

    if (resetNav) context.commit(CLEAR_NAVIGATION);

    await updateField('currentPoint', pointId);
}

export async function navigateToCountry(context, {countryId, resetNav = true}) {
    const updateField = (path, value) => context.commit('updateField', { path, value });

    if (resetNav) context.commit(CLEAR_NAVIGATION);

    await updateField('currentCountry', countryId);
}
