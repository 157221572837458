export default {
    currentCategory: "",
    autohide: true,

    focusOnPrimaryRoutes: true,

    currentCountry: null,
    currentRoute: null,
    currentPoint: null
};
