<template>
    <div>
        <div v-if="dataStatus === 'loaded'" :key="$i18n.locale">
            <GISMap3 />

            <TopRightFloatingPanel />
            <NavMain />
            <ExpandableNav />

            <router-view></router-view>
            <Tutorial></Tutorial>
        </div>
        <div v-if="dataStatus === 'loading'">
            Loading
        </div>
    </div>
</template>

<script>
    import Tutorial from "../Tutorial";
    import TopRightFloatingPanel from "../TopRightFloatingPanel";
    import GISMap3 from "../gis/GISMap3";
    import {mapFields} from "vuex-map-fields";
    import ExpandableNav from "../navigation/ExpandableNav";
    import NavMain from "../navigation/NavMain";
    import {mapActions, mapGetters} from "vuex";
    import {CLEAR_NAVIGATION} from "../../store/mutation-types";

    export default {
        name: 'App',
        components: {
            TopRightFloatingPanel,
            Tutorial,
            GISMap3,
            ExpandableNav,
            NavMain
        },
        data() {
            return {
                right: false,
                color: '',
                colors: [
                    'primary',
                    'blue',
                    'success',
                    'red',
                    'teal',
                ],
                focusOnPrimaryRoutes: true,
            }
        },
        computed: {
            ...mapFields('navigation', ['currentCountry', 'currentRoute', 'currentPoint']),
            ...mapGetters('routes', {routeGetById: 'getById'}),
            ...mapGetters('countries', {countryGetById: 'getById'}),
            ...mapGetters('points', {pointGetById: 'getById'}),
            ...mapFields('gis', {dataStatus: 'data.status'}),
        },
        methods: {
            ...mapActions('navigation', ['navigateToCountry', 'navigateToRoute', 'navigateToRoutePoint', 'navigateToPoint']),
            evaluateRouter() {
                const newRoute = this.$router.currentRoute;

                let countryId, routeId, pointId,
                    routeData, pointData;

                this.$store.commit(`navigation/${CLEAR_NAVIGATION}`);

                switch(newRoute.name) {
                    case 'country':
                        countryId = parseInt(newRoute.params.country_id);

                        this.currentCountry = countryId;
                        break;
                    case 'route':
                        routeId = parseInt(newRoute.params.route_id);
                        routeData = this.routeGetById(routeId);

                        this.currentCountry = routeData.country_id;
                        this.currentRoute = routeId;
                        break;
                    case 'point':
                        pointId = parseInt(newRoute.params.point_id);
                        pointData = this.pointGetById(pointId);

                        this.curentCountry = pointData.country_id;
                        this.currentPoint = pointId;
                        break;
                    case 'route-point':
                        routeId = parseInt(newRoute.params.route_id);
                        pointId = parseInt(newRoute.params.point_id);
                        routeData = this.routeGetById(routeId);

                        this.currentCountry = routeData.country_id;
                        this.currentPoint = pointId;
                        break;
                    default:
                        break;
                }
            }
        },
        watch: {
            '$route': {
                handler(newRoute,oldRoute) {
                    if (this.dataStatus === 'loaded')
                        this.$nextTick(() => this.evaluateRouter());
                }
            },
            dataStatus(newStatus) {
                if (newStatus === 'loaded')
                    this.$nextTick(() => this.evaluateRouter());
            },
            '$i18n.locale': {
                handler() {
                    this.$store.dispatch('gis/fetch_data', this.$i18n.locale);
                },
                immediate: true
            }
        }
    }
</script>

<style>
    .v-overlay-scrim {
        opacity: 0;
    }
    .leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive {
        width: 31px!important;
    }
    .leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive.active {
        border: 2px dashed #ae42bbe0!important;
        border-radius: 350px!important;
        width: 31px!important;
        filter: drop-shadow(2px 4px 6px #ae42bbe0)!important;
        backdrop-filter: blur(20px) saturate(180%)!important;
    }
</style>
