import Vue from 'vue'
import VueRouter from 'vue-router';
import App from '../components/pages/App'
import Index from "../components/pages/Index";
import NotFound from '../components/pages/NotFound'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: App,
        children: [
            {
                name: 'country',
                path: 'country/:country_id',
            },
            {
                name: 'point',
                path: 'points/:point_id',
            },
            {
                name: 'route',
                path: 'routes/:route_id',
                children: [
                    {
                        name: 'route-point',
                        path: 'points/:point_id',
                    }
                ]
            },
            { path: '/', component: Index, name: 'index' },
            { path: '*', component: NotFound, name: 'NotFound' },
        ],
    },
];

// Monkey patch push to ignore NavigationDuplicated exceptions
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.type !== VueRouter.NavigationFailureType.duplicated) return;

        throw err;
    });
}

const router = new VueRouter({
    routes,
    mode: 'history',
});

export default router;
