export function filterByCountry(state, getters, globalState, globalGetters) {
    return countryId => {
        const countryData = globalGetters['countries/getById'](countryId);

        return state.items.filter(_ => _.country_id === countryData.id);
    }
}

export function getById(state) {
    return route_id => {
        return state.items.find(_ => _.id === route_id);
    }
}
