<template>
    <p class="footer">
        <small>
            <span>The REGLPORTS Project is co-funded by the European Union and by National Funds of the Participating Countries participating in the  Interreg IPA CBC Italy-Albania-Montenegro Programme.</span>
            <br>
            <span>This Webpage has been produced with the financial assistance of the European Union. The contents of the webpage are the sole responsibility of Municipality of Termoli and can in no way be taken to reflect the views of the European Union, the participating countries and the Managing Authority.</span>
        </small>
    </p>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>
