import Vue from 'vue'
import Vuex from 'vuex'
import points from './points'
import routes from './routes'
import navigation from './navigation'
import gis from './gis'
import countries from './countries'

Vue.use(Vuex);

export default window.store = new Vuex.Store( {
    namespaced: true,
    modules: {
        gis, points, routes, navigation, countries
    }
});
