<template>
    <v-list dense nav class="py-0">
        <v-list-item two-line class="px-0">
            <v-list-item-content id="detailsContent">
                <h3>{{currentRouteData.title}}</h3>
                <v-divider></v-divider>
                <br>
                <h4 style="">Description</h4>
                <p>{{currentRouteData.description}}</p>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
import {mapFields} from "vuex-map-fields";

export default {
    name: 'NavRoute',
    computed: {
        ...mapFields('navigation', ['currentRoute']),
        ...mapFields('routes', {routes: 'items'}),
        currentRouteData() {
            return this.routes.find(_ => _.id === this.currentRoute);
        }
    }
}
</script>

<style scoped>
h3 {
    margin-top: 1rem;
}
p {
    font-size: small;
    letter-spacing: initial;
    line-height: 1.2rem!important;
    text-align: justify;
}
</style>
