export function getById(state) {
    return country_id => {
        return state.items.find(_ => _.id === country_id);
    }
}

export function getByCode(state) {
    return country_code => {
        return state.items.find(_ => _.code === country_code);
    }
}
