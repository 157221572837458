export default {
    navigation: {
        basemaps: {
            Map: 'Mappa',
            Satelite: 'Satelite',
            Streets: 'Strade'
        },
        focus_primary_routes: 'Focalizza Percorsi Primari'
    }
};
