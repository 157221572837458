<template>
    <v-list dense nav class="py-0">
        <v-list-item two-line class="px-0">
            <v-list-item-content id="detailsContent">
                <v-img
                    v-if="pointData.bannerUrl"
                    class="marker-banner-img"
                    :src="`/images/points/${pointData.bannerUrl}.jpg`"
                    aspect-ratio="1.7"
                    auto
                />
                <h3>{{ pointData.title }}</h3>
                <h4 style="line-height: 2;">{{ pointData.area }}</h4>
                <v-chip
                    class="mr-2 mb-4"
                    color="primary"
                    outlined
                    pill
                    x-small
                >{{ pointData.category }}</v-chip>
                <div class="coords-container">
                    <span class="fas fa-map-pin"></span>
                    <p class="mr-2 mb-4 coords-text">
                        <small>{{ pointData.latlng[0] + ', ' + pointData.latlng[1] }}</small>
                    </p>
                </div>

                <v-divider></v-divider><br>

                <h4 style="">More information</h4>
                <p>{{ pointData.description }}</p>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
import {mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
    name: 'NavPoint',
    computed: {
        ...mapGetters('points', ['getById']),
        ...mapFields('navigation', ['currentPoint']),
        pointData() {
            return this.getById(this.currentPoint);
        }
    }
}
</script>

<style scoped>
h3 {
    margin-top: 1rem;
}
p {
    font-size: small;
    letter-spacing: initial;
    line-height: 1.2rem!important;
    text-align: justify;
}
.coords-container {
    display: flex;
    align-items: center;
    justify-content: left;
}
.coords-text {
    margin-left: .5rem;
    font-size: x-small;
    font-weight: 700;
    opacity: .75;
}
</style>
