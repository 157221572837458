/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue'
import Vuetify from 'vuetify';
import lodash from 'lodash'

import VueTour from 'vue-tour'

import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader

Vue.use(Vuetify);
Vue.use(lodash);
Vue.use(VueTour);

import mitt from 'mitt'

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import router from './router'
import store from './store'
import i18n from './i18n'

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const emitter = mitt();

const app = new Vue({
    el: '#app',
    globalProperties: { emitter },
    store,
    vuetify: new Vuetify({
        icons: {
            iconfont: 'fa',
        },
        theme: {
            themes: {
                light: { primary: '#82358b' },
                dark: { primary: '#82358b' },
            },
        },
    }),
    router,
    i18n
});
