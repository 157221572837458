<template>
    <v-list dense nav class="py-0">
        <v-list-item two-line class="px-0">
            <v-list-item-content>
                <span @click="currentCountry = null" class="menu-icon fas fa-times"></span>
            </v-list-item-content>
            <h1>{{ countryData.name }}</h1>
        </v-list-item>

        <v-divider></v-divider>
        <br>

        <h3>{{ countryData.name }} routes</h3>

        <v-expansion-panels
            :multiple="false"
            ref="routesListPanel"
        >
            <v-expansion-panel
                v-for="route in countryRoutes"
                :key="`route-${route.id}`"
                :ref="`route-${route.id}`"
                link
            >
                <v-expansion-panel-header @click="clickedRoute(route.id)">
                    <v-list-item-icon>
                        <span class="fas fa-route"></span>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ route.title }}</v-list-item-title>
                    </v-list-item-content>

                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <v-list-item
                        v-for="(point, pointIndex) in routeData(route.id).stops"
                        :key="`${route.id}-${pointIndex}`"
                        @click="clickedRoutePoint(route.id, point.id)"
                        link
                        :class="{ active: currentPoint === point.id }">
                        <v-list-item-icon id="stop__icon">
                            <!-- <img :src="iconFromPoint(point)" style="height: 25px; width: 25px"> -->
                            <span class="fas fa-map-pin"></span>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title class="route__title">{{ pointData(point.id).title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-divider></v-divider>
        <br>

        <h3>{{ countryData.name }} points</h3>
        <v-list-item
            v-for="point in countryPoints"
            :key="point.id"
            @click="clickedPoint(point.id)"
            link
            :class="{active: currentPoint === point.id}">

            <v-list-item-icon>
                <!-- <img :src="iconFromPoint(point)" style="height: 25px; width: 25px" /> -->
                <span class="fas fa-map-pin"></span>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ point.title }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>

</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapGetters} from "vuex";

export default {
    name: 'NavCountry',
    computed: {
        ...mapFields('navigation', ['currentCountry', 'currentPoint', 'currentRoute']),
        ...mapFields('countries', {countries: 'items'}),
        ...mapFields('routes', {routes: 'items'}),
        ...mapFields('points', {points: 'items'}),
        ...mapGetters('routes', {filterRoutesByCountry: 'filterByCountry'}),
        ...mapGetters('points', {filterPointsByCountry: 'filterByCountry'}),
        countryPoints() {
            return this.filterPointsByCountry(this.currentCountry);
        },
        countryRoutes() {
            return this.filterRoutesByCountry(this.currentCountry);
        },
        countryData() {
            return this.countries.find(_ => _.id === this.currentCountry);
        },
        routeData() {
            return routeId => this.routes.find(_ => _.id === routeId);
        },
        pointData() {
            return pointId => this.points.find(_ => _.id === pointId);
        }
    },
    mounted() {
        this.$nextTick(() => this.updateScrolling());
    },
    methods: {
        clickedRoutePoint(route_id, point_id) {
            this.$router.push({
                name: 'route-point',
                params: { route_id, point_id }
            });
        },
        clickedRoute(route_id) {
            this.$router.push({
                name: 'route',
                params: { route_id }
            });
        },
        clickedPoint(point_id) {
            this.$router.push({
                name: 'point',
                params: { point_id }
            });
        },
        updateScrolling() {
            if (this.currentRoute === null)
                return;
            const routeBox = this.$refs[`route-${this.currentRoute}`][0];
            console.log(routeBox, routeBox.$el, routeBox.$el.getBoundingClientRect().top);

            // Pre open route

            const routeIndex = this.countryRoutes.findIndex(_ => _.id === this.currentRoute);
            // Need this to find the index of the route in the rendered list
            // it's used internally in v-expansion-panels (inherited behavior from v-group-list)

            if (this.$refs.routesListPanel.internalLazyValue !== routeIndex)
                this.$refs.routesListPanel.updateInternalValue(routeIndex);

            setTimeout(() => {
                // Only scroll if rendered and is not currently in view
                if (routeBox && routeBox.$el)
                    routeBox.$el.scrollIntoView({block: 'start'});
            },1000);
        }
    },
    watch: {
        currentRoute() {
            console.log('currentRoute changed');
            this.$nextTick(() => this.updateScrolling());
        }
    }
}
</script>

<style scoped>

</style>
