// DEPRECATED
export function getPoint(state) {
    return pointId => state.items.find(_ => _.id === pointId);
}

export function getById(state) {
    return pointId => state.items.find(_ => _.id === pointId);
}

export function filterByCountry(state, getters, globalState, globalGetters) {
    return countryId => {
        return state.items.filter(_ => _.country === countryId);
    }
}
