import {} from '../mutation-types';

/*export function action({commit}, payload) {
    commit(MUTATION_TYPE, payload);
}*/

export async function fetch_data(context, locale) {
    const updateField = (path, value) => context.commit('updateField', { path, value });
    updateField('data.status', 'loading');

    const res = await fetch(`/api/gis?locale=${locale}`);
    const body = await res.json();

    if (body.status !== 'ok') {
        updateField('data.status', 'error');
        updateField('data.error', 'API error');
    }
    const data = body.data;

    console.log({data});

    updateField(`data.localeCache.${locale}`, body.data);

    context.dispatch('points/setCategories', data.categories, {root:true});
    context.dispatch('routes/setItems', data.routes, {root:true});
    context.dispatch('points/setItems', data.points, {root:true});
    updateField('ways', data.ways);
    context.dispatch('countries/setItems', data.countries, {root:true});

    updateField('data.status', 'loaded');
}
