<template>
    <div class="top-right links">
        <img class="logo" src="/images/logo.jpg" width="250" alt="">
        <div class="languages">
            <LangSwitcher />
        </div>
    </div>
</template>

<script>
    import categories from '../data/categories'
    import LangSwitcher from './LangSwitcher'
    export default {
        name: "TopRightFloatingPanel",
        components: {LangSwitcher},
        computed: {
            legendIcons() {
                return categories;
            }
        }
    }
</script>

<style scoped>
.languages {
    width: 100%;
    text-align: center;
    margin-bottom: 4px;
}
</style>
