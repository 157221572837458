<template>
    <span class="mx-2">
        <a :class="{ 'fancy-btn': true, active: locale === 'it'}" @click.prevent="switchLang('it')">
            <country-flag country="it" />
        </a>
        <a :class="{ 'fancy-btn': true, active: locale === 'en'}" @click.prevent="switchLang('en')">
            <country-flag country="gb" />
        </a>
    </span>
</template>

<script>
import CountryFlag from 'vue-country-flag'
export default {
    name: "LangSwitcher.vue",
    components: { CountryFlag },
    methods: {
        switchLang: function (locale) {
            this.$i18n.locale = locale;
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale;
        },
        langStyles(locale) {
            return locale => ({ 'background-color': (this.locale === locale ? ' #a6c8ff' : 'transparent') });
        }
    },
    mounted() {
        if (localStorage.lang)
            this.switchLang(localStorage.lang);
    },
    watch: {
        '$i18n.locale': function(n,o) {
            localStorage.lang = n;
        }
    }
}
</script>

<style scoped>
.fancy-btn {
    border-style: solid;
    border-color: transparent;
    border-radius: 0.25rem;
    border-width: 2px;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 5px 5px 1px 5px;
}

.fancy-btn:focus{
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.fancy-btn{
    text-align: center;
    text-transform: uppercase;
}

.fancy-btn.active {
    border-color: #72a8ff;
}
</style>
