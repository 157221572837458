<template>
    <v-navigation-drawer
        :expand-on-hover="false"
        :mini-variant="false"
        :right="false"
        :permanent="true"
        :floating="false"
        :absolute="true"
        :class="getClasses"

        v-if="isEnabled"
    >
        <NavRoute v-if="panel === 'route'" />
        <NavPoint v-if="panel === 'point'" />
    </v-navigation-drawer>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import NavRoute from './NavRoute'
import NavPoint from './NavPoint'

export default {
    name: 'ExpandableNav',
    components: {NavRoute,NavPoint},
    methods: {},
    computed: {
        ...mapFields('navigation', ['currentRoute', 'currentPoint', 'autohide']),
        getClasses() {
            return {
                'slide-over-to-right': this.extended,
                'slide-over-to-left': !this.extended,
                'nav-is-open': true,
                'marker-info-panel': true
            }
        },
        extended() {
            if (!this.autohide)
                return true;

            return this.isEnabled;
        },
        isEnabled() {
            return this.currentRoute !== null || this.currentPoint !== null;
        },
        panel() {
            if (this.currentPoint !== null)
                return 'point'

            if (this.currentRoute !== null)
                return 'route'

            return null;
        }
    }
}
</script>
