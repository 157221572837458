import VueI18n from "vue-i18n";
import Vue from 'vue'
Vue.use(VueI18n);
import it from './it/index.js'
import en from './en/index.js'

const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en',
    messages: {
        it,
        en
    }
});

export default i18n;
