<template>
    <v-list dense nav class="py-0">
        <v-list-item two-line class="nav__toggle" :class="autohide && 'px-0'">
            <v-list-item-content class="v-step-1">
                <span
                    @click="autohide = !autohide"
                    :class="{
                        'menu-icon fas': true,
                        'fa-bars': autohide,
                        'fa-angle-double-left': !autohide
                    }"
                ></span>
            </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item
            v-for="(item, index) in basemaps"
            :key="item.title"
            @click="basemapClicked(item)"
            :class="{ 'v-step-2': index === 0, active: basemap === item.name }"
            link
        >
            <v-list-item-icon>
                <span :class="item.icon"></span>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{
                    $t(`navigation.basemaps.${item.title}`)
                }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item
            v-for="country in countriesList"
            :key="country.name"
            @click="countryClicked(country.id)"
            class="v-step-4"
            link
        >
            <v-list-item-icon>
                <span :class="`flag-icon flag-icon-${country.code}`"></span>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ country.name }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
            @click="focusOnPrimaryRoutes = !focusOnPrimaryRoutes"
            class="v-step-4"
            link
        >
            <v-list-item-icon>
                <span class="fas fa-exclamation"></span>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title
                    >Focus Primary Routes:
                    {{ focusOnPrimaryRoutes ? "On" : "Off" }}</v-list-item-title
                >
            </v-list-item-content>
        </v-list-item>
        <v-list-item
            :disabled="lang.code === 'al' || lang.code === 'gr'"
            v-for="lang in languages"
            :key="lang.name"
            @click.prevent="switchLang(lang.code)"
            class="v-step-4 mdhidden"
            link
        >
            <v-list-item-icon>
                <span :class="`flag-icon flag-icon-${lang.country}`"></span>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ lang.name }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item
            v-for="category in categoriesList"
            :key="category.title"
            @click="categoryClicked(category)"
            class="v-step-5"
            active="currentCategory === category"
            link
        >
            <v-list-item-icon>
                <img
                    width="25"
                    height="25"
                    :src="`/images/${category.icon}`"
                    alt=""
                />
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{
                    category.name || "All"
                }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-divider />

        <Footer />
    </v-list>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import Footer from "../Footer";
import CountryFlag from "vue-country-flag";

export default {
    name: "NavMenu",
    components: {
        Footer
    },
    data() {
        return {
            languages: [
                {
                    id: 1,
                    code: "en",
                    name: "English",
                    country: "gb"
                },
                {
                    id: 2,
                    code: "it",
                    name: "Italian",
                    country: "it"
                }
            ]
        };
    },
    computed: {
        locale() {
            return this.$i18n.locale;
        },
        countriesList() {
            return this.countries;
        },
        categoriesList() {
            return [{ name: "", icon: "default.png" }, ...this.categories];
        },
        ...mapFields("navigation", [
            "currentCountry",
            "currentCategory",
            "autohide"
        ]),
        ...mapFields("points", ["categories"]),
        ...mapFields("gis", {
            basemaps: "availableBasemaps",
            basemap: "basemap"
        }),
        ...mapFields("countries", { countries: "items" }),
        ...mapFields("navigation", [
            "focusOnPrimaryRoutes",
            "currentCategory",
            "autohide"
        ])
    },
    methods: {
        switchLang: function(locale) {
            this.$i18n.locale = locale;
        },
        basemapClicked(basemap) {
            this.basemap = basemap.name;
        },
        countryClicked(country_id) {
            this.$router.push({
                name: "country",
                params: { country_id }
            });
        },
        categoryClicked(category) {
            this.currentCategory = category.id;
        }
    }
};
</script>
