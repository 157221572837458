export default {
    navigation: {
        basemaps: {
            Map: 'Map',
            Satelite: 'Satelite',
            Streets: 'Streets'
        },
        focus_primary_routes: 'Focus Primary Routes'
    }
};
