import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';
import state from './state';
import {getField,updateField} from 'vuex-map-fields'

export default {
    namespaced: true,
    mutations: { ...mutations, updateField },
    getters: { ...getters, getField },
    actions,
    state
}
