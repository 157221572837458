import {CLEAR_NAVIGATION} from '../mutation-types'

export default {
    [CLEAR_NAVIGATION]: function(state) {
        state.currentCountry = null;
        state.currentPoint = null;
        state.currentRoute = null;
        state.focusOnPrimaryRoutes = null;
        state.currentCategory = '';
    }
}
