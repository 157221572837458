const categories = [{ name: 'Activity', icon: 'activity.png'},
    { name: 'Church', icon: 'church.png'},
    { name: 'Culture', icon: 'culture.png'},
    { name: 'Fortress', icon: 'fortress.png'},
    { name: 'Gastronomy', icon: 'gastronomy.png'},
    { name: 'Hotel', icon: 'hotel.png'},
    { name: 'Isole', icon: 'isole.png'},
    { name: 'Marina', icon: 'marina.png'},
    { name: 'Museum', icon: 'museum.png'},
    { name: 'Nature', icon: 'nature.png'},
    { name: 'Nature Reserve', icon: 'nature-reserve.png'},
    { name: 'Park', icon: 'park.png'},
    { name: 'Port', icon: 'port.png'},
    { name: 'River', icon: 'river.png'},
    { name: 'Lake', icon: 'lake.png'},
    { name: 'Seaside', icon: 'seaside.png'},
    { name: 'Town', icon: 'town.png'},
    { name: 'Molisian Croatian', icon: 'molisian_croatian.png'}
];

export default categories;
