<template>
    <v-tour name="mapTour" :steps="steps"></v-tour>
</template>

<script>
    export default {
        name: "Tour",
        data() {
            return {
                steps: [
                    {
                        target: '.v-step-1',
                        header: {
                            title: 'Get Started',
                        },
                        content: 'Discover <strong>the Reglports GIS</strong>!',
                        params: {
                            placement: 'left'
                        }
                    },
                    {
                        target: '.v-step-1',
                        content: 'Here you can toggle the navigation behaviour! You can use the minimized version or the fixed one.',
                        params: {
                            placement: 'left'
                        }
                    },
                    {
                        target: '.v-step-2',
                        content: 'Here you can select your map view!',
                        params: {
                            placement: 'left'
                        }
                    },
                    {
                        target: '.v-step-3',
                        content: 'You can also select your map view here!',
                        params: {
                            placement: 'bottom'
                        }
                    },
                    {
                        target: '.v-step-4',
                        content: 'Here you can select a country to expand the thematic points and routes',
                        params: {
                            placement: 'left'
                        }
                    },
                    {
                        target: '.v-step-5',
                        content: 'Here you can filter the points based on the category',
                        params: {
                            placement: 'left'
                        }
                    },
                    {
                        target: '.v-step-6',
                        content: 'Lastly, you can use full screen if you like!',
                        params: {
                            placement: 'right'
                        }
                    }
                ]
            }
        },
        mounted: function () {
            return;
            if (this.$router.currentRoute.name !== 'NotFound')
                this.$tours['mapTour'].start()
        },
    }
</script>

<style scoped>
    .v-tour {
        z-index: 15;
        position: relative;
    }
</style>
