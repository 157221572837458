export default {
    basemap: 'Topographic',
    availableBasemaps: [
        { title: 'Map', icon: 'fas fa-map', name: 'Topographic', url: '/images/Map.PNG', esriName: 'Topographic' },
        { title: 'Satelite', icon: 'fas fa-globe-europe', name: 'Imagery', url: '/images/Satelite.PNG', esriName: 'Imagery' },
        { title: 'Streets', icon: 'fas fa-road', name: 'Streets', url: '/images/Streets.PNG', esriName: 'Streets' }
    ],
    data: {
        status: '',
        error: '',
        localeCache: {}
    },
    ways: []
}
