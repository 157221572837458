<template>
    <div style="height:100%; width: 100%; position: fixed; z-index: 5">
        <span
            style="top: 2px; left:5px; position:absolute;z-index: 9999; font-weight: bold; "
            class="mdhidden"
            >&rarr; Swipe to open the menu</span
        >
        <div
            style="top: 5px; right:5px; position:absolute;z-index: 9999;"
            class="mdhidden"
        >
            <img class="logo" src="/images/logo.jpg" width="200" alt="" />
        </div>
        <div
            style="height:100%; width: 100%; position: absolute"
            id="map-container"
        ></div>
    </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import categories from "../../data/categories";
import { mapGetters } from "vuex";

export default {
    name: "GISMap3",
    data() {
        return {
            map: null,
            timer: null,
            layers: {
                basemap: null,
                points: [],
                routes: []
            }
        };
    },
    mounted() {
        //this.timer = setInterval( () => this.map.invalidateSize(), 100);
        let initCoords = [42.03577, 17.056411];
        let initZoom = 8;

        if (this.flyTo) {
            initCoords = this.flyTo;
            initZoom = 12;
        }

        this.map = L.map("map-container").setView(initCoords, initZoom);

        const esriBasemap = this.basemaps.find(
            _ => _.name === this.currentBasemap
        ).esriName;
        this.loadBasemapLayer(esriBasemap);

        this.initPoints();
        this.initPolylines();

        this.updatePoints();
        this.updatePolylines();
    },
    methods: {
        invalidateSize() {
            //
        },
        generateIconFromCategory(category) {
            const src = category ? category.icon : "default.png";

            return L.icon({
                iconUrl: `/images/${src}`,
                iconSize: [27, 31],
                iconAnchor: [13.5, 17.5],
                popupAnchor: [0, -11]
            });
        },
        initPoints() {
            this.points.map(point => {
                const category = this.categories.find(
                    item => item.id === point.category
                );
                const icon = this.generateIconFromCategory(category);

                const marker = L.marker(point.latlng, {
                    icon: icon
                });
                this.layers.points.push({
                    id: point.id,
                    marker
                });
            });
        },
        initPolylines() {
            this.routes.map(route => {
                const polyline = this.routePolylines.find(
                    _ => _.id === route.id
                ).data;
                const polylineLayer = L.polyline(polyline);

                this.layers.routes.push({
                    id: route.id,
                    polyline: polylineLayer
                });

                polylineLayer.on("click", e => {
                    this.$store.dispatch(
                        "navigation/navigateToRoute",
                        route.id
                    );
                });
            });
        },
        updatePoints() {
            this.layers.points.map(point => {
                if (this.enabledPoints.find(id => point.id === id))
                    point.marker.addTo(this.map);
                else point.marker.removeFrom(this.map);
            });
        },
        updatePolylines() {
            this.layers.routes.map(routeLayer => {
                if (this.enabledRoutes.find(id => routeLayer.id === id)) {
                    const routeData = this.routes.find(
                        _ => _.id === routeLayer.id
                    );
                    const color = this.calculateRouteColor(routeData);
                    routeLayer.polyline.addTo(this.map).setStyle({ color });
                } else {
                    routeLayer.polyline.removeFrom(this.map);
                }
            });
        },
        calculateRouteColor(route) {
            return (
                route.theme +
                (this.focusOnPrimaryRoutes && !route.primary ? "48" : "cc")
            );
        },
        loadBasemapLayer(esriBasemap) {
            if (this.layers.basemap) this.map.removeLayer(this.layers.basemap);

            this.layers.basemap = L.esri
                .basemapLayer(esriBasemap)
                .addTo(this.map);
            this.map.addLayer(this.layers.basemap);
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    computed: {
        ...mapFields("gis", {
            basemaps: "availableBasemaps",
            currentBasemap: "basemap"
        }),
        ...mapFields("routes", { routes: "items" }),
        ...mapFields("points", { points: "items", categories: "categories" }),
        ...mapFields("navigation", [
            "focusOnPrimaryRoutes",
            "currentCategory",
            "currentRoute",
            "currentPoint"
        ]),
        ...mapGetters("gis", [
            "enabledPoints",
            "enabledRoutes",
            "routePolylines",
            "flyTo"
        ])
    },
    watch: {
        focusOnPrimaryRoutes() {
            this.updatePoints();
            this.updatePolylines();
        },
        currentCategory() {
            this.updatePoints();
            this.updatePolylines();
        },
        currentBasemap() {
            this.loadBasemapLayer(this.currentBasemap);
        },
        flyTo(newVal) {
            if (newVal && this.map) this.map.flyTo(newVal, 12);
        }
    }
};
</script>
