import {} from '../mutation-types';

/*export function action({commit}, payload) {
    commit(MUTATION_TYPE, payload);
}*/

export function setItems(context, items) {
    const updateField = (path, value) => context.commit('updateField', { path, value });

    updateField('items', items);
}
